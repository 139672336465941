<template>
  <div class="table-of-contents">
    <div class="space"></div>
    <div
      v-for="item in menuItems"
      :key="item.anchorId"
      class="chapter"
      :class="{'is-active': activeChapterId === item.anchorId}"
    >
      <a
        :href="`#${item.anchorId}`"
        @click="scrollToAnchor(item.anchorId)"
        class="chapter-link"
        :class="{'is-highlighted': activeItemId === item.anchorId}"
      >
        {{ item.text }}
      </a>

      <div class="sub-sections" :class="{expanded: activeChapterId === item.anchorId && item.children.length}">
        <a
          v-for="child in item.children"
          :key="child.anchorId"
          :href="`#${child.anchorId}`"
          @click="scrollToAnchor(child.anchorId)"
          class="sub-section-link"
          :class="{'is-highlighted': activeItemId === child.anchorId}"
        >
          {{ child.text }}
        </a>
      </div>
    </div>
    <div class="space"></div>
  </div>
</template>

<script>
import throttle from 'lodash/throttle';
import {mapGetters} from 'vuex';

export default {
  mounted() {
    this.menuItems = this.generateMenuItems();
    window.addEventListener('scroll', this.onContentScroll);

    if (this.$route.hash) {
      setTimeout(() => {
        this.scrollToAnchor(this.$route.hash.replace('#', ''));
      }, 1000);
    } else if (this.menuItems && this.menuItems.length) {
      this.setActive(this.menuItems[0].anchorId);
    }
  },
  data() {
    return {
      menuItems: [],
      activeItemId: '',
      activeChapterId: '',
      isScrolling: false,
    };
  },
  computed: {
    ...mapGetters(['familyWebsiteDetailsState']),
    menuItemsFlat() {
      let itemsFlat = [];
      this.menuItems.forEach(item => {
        itemsFlat.push(item);
        item.children.forEach(child => {
          itemsFlat.push(child);
        });
      });
      return itemsFlat;
    },
  },
  methods: {
    onContentScroll() {
      if (this.isScrolling) {
        return;
      }
      this.highlightToc();
    },
    highlightToc: throttle(function (event) {
      let anchor = this.menuItemsFlat[0];
      for (let item of this.menuItemsFlat) {
        let isInViewport = this.isContentSectionInViewport(item);
        if (isInViewport) {
          anchor = item.anchorId;
        } else {
          if (!this.isActive(anchor)) {
            this.setActive(anchor);
          }
          return;
        }
      }
      if (!this.isActive(anchor)) {
        this.setActive(anchor);
      }
    }, 200),
    isContentSectionInViewport(item) {
      let offset = window.innerHeight * 0.2;
      let scrollTop = window.scrollY;
      const element = document.getElementById(item.anchorId);
      return scrollTop + offset >= element.offsetTop;
    },
    isActive(anchorId) {
      return this.activeItemId === anchorId;
    },
    scrollToAnchor(anchorId) {
      this.$scrollTo(document.getElementById(anchorId), 500, {
        force: true,
        lazy: false,
        cancelable: false,
        onStart: () => {
          this.isScrolling = true;
        },
        onDone: element => {
          this.setActive(anchorId);
          this.isScrolling = false;
        },
      });
    },
    setActive(anchorId) {
      this.activeItemId = anchorId;
      this.activeChapterId = this.getChapterItemByAnchorId(anchorId).anchorId;
    },
    getChapterItemByAnchorId(anchorId) {
      for (let item of this.menuItems) {
        if (item.anchorId === anchorId) {
          return item;
        }
        for (let child of item.children) {
          if (child.anchorId === anchorId) {
            return item;
          }
        }
      }
    },
    generateMenuItems() {
      let menuItems = [];
      const headings = Array.from(document.querySelectorAll('.content h2, .content h3'));
      headings.forEach((item, index) => {
        item.id = index;
        let itemData = {text: item.innerText, anchorId: item.id, children: []};

        if (item.tagName === 'H2') {
          menuItems.push(itemData);
        } else if (menuItems.length) {
          menuItems[menuItems.length - 1].children.push(itemData);
        }
      });
      return menuItems;
    },
  },
  name: 'TableOfContents',
};
</script>

<style lang="scss" scoped>
.table-of-contents {
  height: 100%;
  overflow-y: scroll;
  padding: 0;
  background: var(--report-menu-toc-background);

  scrollbar-base-color: var(--scrollbar-base-color);
  scrollbar-face-color: var(--scrollbar-face-color);
  scrollbar-3dlight-color: var(--scrollbar-3dlight-color);
  scrollbar-highlight-color: var(--scrollbar-highlight-color);
  scrollbar-track-color: var(--scrollbar-track-color);
  scrollbar-arrow-color: var(--scrollbar-arrow-color);
  scrollbar-shadow-color: var(--scrollbar-shadow-color);
  scrollbar-darkshadow-color: var(--scrollbar-darkshadow-color);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb);
  }

  .space {
    height: var(--report-menu-sites-closed-height);
  }

  .chapter {
    &.is-active {
      background-color: var(--report-menu-toc-chapter-expanded-background);
      padding: 20px 0;
    }

    a {
      color: var(--report-menu-toc-link-color);
      font-weight: bold;
      transition: color 0.5s ease;
      text-decoration: none;

      &:hover {
        color: var(--report-menu-toc-link-color-hover);
      }

      &.is-highlighted {
        color: var(--report-menu-toc-link-color-active);
      }
    }
    .sub-sections {
      max-height: 0;
      overflow: hidden;

      &.expanded {
        max-height: 10000px;
        transition: max-height 2s ease;
      }
    }
    .chapter-link {
      padding: 10px 30px 10px 30px;
      margin: 10px auto;
      display: block;
    }
    .sub-section-link {
      padding: 10px 30px 10px 50px;
      display: block;
      font-size: 0.9em;
    }
  }
}
</style>
